<template>
	<div class="About w pt24 flex">
		<div class="left">
			<ul class="br4 bgfff">
				<li v-for="(item, index) in leftArr" :key="index">
					<router-link :to="{ name: item.pathName }" class="pr00">{{
						item.txt
					}}</router-link>
				</li>
			</ul>
		</div>
		<div class="right br4">
			<keep-alive include="Collection,Footprint">
				<router-view></router-view>
			</keep-alive>
		</div>
	</div>
</template>
<script>
export default {
	name: "About",
	data() {
		return {
			leftArr: [
				{
					pathName: "Personal",
					txt: "个人中心",
				},
				{
					pathName: "Order",
					txt: "我的订单",
				},
				{
					pathName: "Vip",
					txt: "我的会员",
				},
				// {
				// 	pathName: "Release",
				// 	txt: "我的发布",
				// },
				{
					pathName: "Collection",
					txt: "我的收藏",
				},
				{
					pathName: "Footprint",
					txt: "我的足迹",
				},
				{
					pathName: "Integral",
					txt: "我的积分",
				},
				{
					pathName: "Wallet",
					txt: "我的钱包",
				},
				{
					pathName: "Record",
					txt: "下载记录",
				},
				{
					pathName: "Invoice",
					txt: "电子发票",
				},
				{
					pathName: "Share",
					txt: "分享APP",
				},
				{
					pathName: "Help",
					txt: "帮助中心",
				},
				{
					pathName: "Contact",
					txt: "联系我们",
				},
				{
					pathName: "Message",
					txt: "系统消息",
				},
				{
					pathName: "Setting",
					txt: "系统设置",
				},
			],

			isCurrentView: false, // 当前是否在当前页面
			scorllTop: 0, // 距离顶部得距离
		};
	},
	mounted() {
		window.addEventListener("scroll", this.aboutScroll);
	},
	// keep-alive 活跃的
	activated() {
		this.isCurrentView = true;
		if (this.scorllTop > 0) {
			document.documentElement.scrollTop = this.scorllTop;
			document.body.scrollTop = this.scorllTop;
		}
	},
	// keep-alive 失去活跃
	deactivated() {
		this.isCurrentView = false;
	},
	destroyed() {
		window.removeEventListener("scroll", this.aboutScroll);
	},
	methods: {
		aboutScroll() {
			if (this.isCurrentView) {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.About {
	@extend .h100;
	padding-bottom: 64px;

	> .left {
		min-width: 240px;

		ul {
			padding: 16px 0;

			li {
				padding: 16px 0;

				a {
					padding-left: 70px;
					font-size: 16px;
					font-weight: 400;
					color: #666666;

					&.router-link-active {
						color: #ff8300;
						font-weight: 700;

						&:after {
							content: "";
							display: block;
							border: 4px solid #ff8300;
							border-radius: 50%;
							width: 14px;
							height: 14px;
							box-sizing: border-box;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: 32px;
						}
					}
				}
			}
		}
	}

	> .right {
		flex: 1;
		padding-left: 24px;
	}
}
</style>
